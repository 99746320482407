import styled from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.section`
  display: flex;
  align-self: center;
  flex-direction: column;
  width: 100%;
  max-width: 1140px;

  margin-bottom: 3rem;

  div:not(:first-child) {
    /* background: crimson; */

    padding-top: 3rem;
    margin-top: 3rem;
    border-top: 1px solid #dedede;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 0.75rem;
    text-align: center;
    color: ${(props) => props.color};
    
    width: 180px;
    padding: 0.25rem 0;
    border-radius: 0.25rem;
    border: 1px solid ${(props) => props.color};
    margin-bottom: 0.5rem;

    &:hover {
      color: #FFF;
      cursor: pointer;
      background: ${(props) => props.color};
    }
  }

  h2 {
    font-size: 1.75rem;
    margin-bottom: 1rem;
  }

  a {
    font-weight: 300;
    font-size: 1.125rem;
    padding: 1.25rem 0.75rem;
    box-shadow: 0px 3px 10px #373d4f26;
    border-left: 4px solid ${(props) => props.color};

    & + a {
      margin-top: 1rem;
    }

    opacity: 0.8;
    transition-duration: 0.6s;

    &:hover {
      opacity: 1;
      transform: translateX(12px);
    }
  }

  ${media.lessThan('large')`
    padding: 0 6px;
    margin-bottom: 20px;
    span {
      font-size: 12px;
    }
    strong {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 5px;
    }
    & + div {
      padding-top: 20px;
      border-top: 1px solid rgba(0,0,0,0.2);
    }
    a {
      font-size: 14px;
      padding: 10px 5px;
      box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.2);
    }
  `};
`;
