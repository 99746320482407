import React from 'react';
import { FaNewspaper } from 'react-icons/fa';

import SEO from '~/components/SEO';
import News from '~/components/News';
import Title from '~/components/Title';
import Layout from '~/components/Layout';

const NewsPage = () => (
  <Layout>
    <SEO title="Noticias" />
    <Title content="Últimas notícias dos principais portais" icon={<FaNewspaper />} />
    <News />
  </Layout>
);

export default NewsPage;
