import Axios from 'axios';
import React, { useEffect, useState } from 'react';

import { Container, Content } from './styles';

const News = () => {
  const [news, setNews] = useState([]);

  useEffect(() => {
    async function loadNews() {
      const { data } = await Axios.get('https://casagrande-adv-api.vercel.app/api/get-news');
      setNews([
        { link: 'http://portal.stf.jus.br', title: 'STF - Supremo Tribunal Federal', content: data.stf.slice(0, 7), color: '#339965' },
        // { link: 'http://www.tse.jus.br', title: 'TSE - Tribunal Superior Eleitoral', content: response.stf, color: '#ef4545' },
        { link: 'http://www.tst.jus.br', title: 'TST - Tribunal Superior do Trabalho', content: data.tst.slice(0, 7), color: '#3b79a9' },
        { link: 'https://www.trf4.jus.br', title: 'TRF4 - Tribunal Regional Federal da 4ª Região', content: data.trf4.slice(0, 7), color: '#993399' }
      ]);
    }
    loadNews();
  }, []);

  return (
    <Container>
      {news.map((newsPortal) => (
        <Content key={newsPortal.link} color={newsPortal.color}>
          <span>{newsPortal.link}</span>
          <h2>{newsPortal.title}</h2>

          {newsPortal.content.map((newsItem) => (
            <a key={newsItem.url} href={newsItem.url} target="__new">{newsItem.title}</a>
          ))}
        </Content>
      ))}
    </Container>
  );
};
export default News;
